#article{
    max-width: 100vw;
    margin-bottom: 4vh;
}

.mb-4{
    margin-bottom: 4vh;
}


@media screen and (min-width: 1200px) {
    #article{
        max-width: 50vw;
    }
}