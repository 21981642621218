.first-home-container{
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.home-flexbox{
    display: flex;
    justify-content: center;
    -ms-box-orient: horizontal;
    flex-wrap: wrap;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 300px;
    border-radius:8px;
    margin: 16px;
    height: 350px;
  }

.home-img{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    overflow: hidden;
    border-radius:8px;
 
}

.solo-button{
    margin-top: 6vh;
    margin-bottom: 2vh;
}

.overflow{
    overflow: hidden;
    max-height: 50%;
    border-radius: 8px;
}

.container {
padding: 2px 16px;
height: 50%;
display: flex;
justify-content: center;
align-items: center;
}

.title{
    margin-top: 2vh;
    margin-bottom:2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    border-bottom: 2px solid #76FF03;
}

.writer{
    margin-left: 4vw;
}

.argument{
    margin-left: 2vw;
    margin-top: 1vh;
    padding: 0;
}

.photo{
    padding: 2vw;
}

.description{
    padding-left:2vw;
    padding-right: 1vw;
}

.content{
    padding-left:2vw;
    padding-right: 1vw;
}

a{
    margin: 0;
}

@media only screen and (min-width: 900px) {
    .div-form{
        width: 50vw;
    }


}

@media only screen and (min-width: 1300px) {
    .div-form{
        width: 50vw;
    }

    
    .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 500px;
    border-radius:8px;
    margin: 16px;
  }

}
