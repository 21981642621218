/*Buttons*/
.sp-btn{
    border: none;
    padding:0.7rem;
    margin: 4px;
    border-radius: 4px;
    text-decoration: none;
  }

.sp-btn:focus{
    outline: none;
}

a{
    text-decoration: none;
    outline: none;
}

.pd-btn{
    padding-left: 3vw;
    padding-right: 3vw;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

/*Colors bg and font */
.bg-color-black{
    background-color: black;
}

.bg-color-red{
    background-color: red;
}

.bg-color-fluo{
    background-color: #76FF03;
}

.bg-color-tras{
    background-color: transparent;
}

.bg-color-orange{
    background-color: #F57C00;
}

.orange-hover{
    background-color:rgb(141, 95, 48);
}

.color-orange{
    color: #F57C00;
}

.color-black{
    color: black;
}

.color-white{
    color: white;
}

.color-fluo{
    color: #76FF03;
}

.box-shadow-black{
    box-shadow:  0px 7px 6px -4px black;
}


/*List*/
.sp-x-list{
    display: flex;
    align-items: center;
}

/*Containers*/

.sp-overflow-x{
    display: flex;
    align-items: center;
    overflow-x: scroll;
}

/* width */
::-webkit-scrollbar {
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.sp-flex{
    display: flex;
}

.sp-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sp-block{
    display: block;
}

.sp-h-center{
    display: flex;
    align-items: center;
}

.sp-w-center{
    display: flex;
    justify-content: center;
}

.sp-space-between{
    display: flex;
    justify-content: space-between;
}

.sp-fluid{
    display: block;
}

.sp-pd-fluid{
    padding-left: 0;
    padding-right:0;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.sp-smooth{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.sp-smooth-up{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.text-align-center{
    text-align: center;
}

.sp-prova{
    border-radius: 16px;
    background-color: orange;
    width: 70vw;
    height: 35vh;
    margin-bottom: 12px;
}

.sp-input{
    border: none;
    border-bottom: solid 2px black;
    padding: 6px;
    margin: 4px;
}

.sp-inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.sp-input:focus{
    outline: none;
}

.sp-card{
    border: solid 2px black;
    border-radius: 10px;
    margin: 8px;
}

.sp-bline{
    border-bottom: solid 3px #76FF03;
    margin: 8px;
}

.none{
    display: none;
}

/*Spacing*/

.w-50{
    width: 50%;
}

.w-100{
    width: 100%;
}

.w-fluid{
    width: 100%;
}

.w-searchbar{
    width: 70vw;
}

.m-basic{
    margin: 4px;;
}

.pd-sp{
    padding: 16px;
}

.pd-8{
    padding-left: 8vw;
    padding-right: 8vw;
}

.first-mobile{
    margin-left: 8px;
}

.last-mobile{
    margin-right: 8px;
}

.fixed{
    position: fixed;
}

.absolute{
    position: absolute;
}

/*text*/
.txt-m{
    font-size: large;
}

.txt-l{
    font-size: x-large;
}

.txt-xl{
    font-size: xx-large;
}

.sp-txt-container{
    width: 90vw;
}

.sp-s-txt-container{
    width: 90vw;
}

/*Images*/
.sp-img{
    width: 70vw;
    border-radius: 5px;
    box-shadow:  0px 7px 6px -4px #76FF03;
}

.sp-s-img{
    width: 100px;
    border-radius: 5px;
    box-shadow:  0px 7px 6px -4px #76FF03;
}

/*Effect*/

.sp-b-effect{
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}



@media screen and (min-width: 1200px) {

    .sp-overflow-x{
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: scroll;
    }

    ::-webkit-scrollbar {
        height: 5px;
        width: 7px;
      }

    .sp-smooth{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .sp-smooth-up{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .sp-fluid{
        display: flex;
        justify-content: center;
    }

    .sp-pd-fluid{
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 0;
        padding-bottom: 0;
    }

    .sp-card{
        border: solid 3px black;
        border-radius: 15px;
        width: 25vw;
        margin: 8px;
    }

    .sp-img{
        width: 25vw;
        border-radius: 5px;
    }

    .sp-txt-container{
        width: 50vw;
    }

    .sp-s-txt-container{
        width: 20vw;
    }

    .w-fluid{
        width: 50%;
    }

  }