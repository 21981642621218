#menu{
    display: block;
    width: 100%;
    padding-top: 1vh;
    padding-bottom:1vh;
    font-family: 'Lobster', cursive;
    
}

.top-menu{
    display: flex;
    justify-content: space-between;
}

.line1{
    height: 2px;
    width: 1.5rem;
    background-color: white;
    margin: 4px;
    border-radius: 10px;
}

.line2{
    height: 3px;
    width: 2.5rem;
    background-color: white;
    margin: 6px;
    border-radius: 2px;
}

.menu-computer{
    display: none;
}

.menu-phone{
    display: block;
}

.menu-logo{
    max-width: 100px;
}

@media screen and (min-width: 1200px) {
    .menu-phone{
        display: none;
    }
    .menu-computer{
        display: flex;
    }
  }
  