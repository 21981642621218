.about-title{
    margin-top: 5vh;
    border-bottom: 0px solid #76FF03;
    margin-right: 2vw;
    margin-left: 2vw;
}

.social-about{
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
}

.icon{
    margin-left:10vw;
    margin-right:10vw;
    margin-top: 2vh;
}

.icon-img{
    height:50px;
}

@media only screen and (min-width: 1300px) {
    .div-form{
        width: 50vw;
    }

    .about-title{
        margin-top: 5vh;
        border-bottom: 3px solid #76FF03;
        margin-right: 2vw;
        margin-left: 2vw;
    }
}
