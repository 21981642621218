body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  min-height: 100vh;
  padding-bottom: 250px;
}

.App{

}

code{
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

h1{
  padding: 0;
  margin: 0;
}

h2{
  padding: 0;
  margin: 0;
}

h3{
  padding: 0;
  margin: 0;
}

h4{
  padding: 0;
  margin: 0;
}

h5{
  padding: 0;
  margin: 0;
}

h6{
  padding: 0;
  margin: 0;
}

p{
  margin: 0;
}

.smooth{
  border-radius: 8px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.footer-logo{
  margin-right: 3vw;
  font-family: 'Lobster', cursive;
}

.footer-margin{
  margin-top: 25vh;
}

h1{
  margin: 0;
  padding: 0;
}

